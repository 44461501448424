import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { graphql, StaticQuery } from "gatsby";

const localization = {
  altText: "Company Logo - Fancy",
};

const FancyLogo = ({ size }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "color_overthetop_logo.png" }) {
          childImageSharp {
            fixed(width: 124) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fixed;
      return <Img fixed={imageData} />;
    }}
  />
);

FancyLogo.propTypes = {
  size: PropTypes.number,
};

FancyLogo.defaultProps = {
  size: 124,
};

export default FancyLogo;
