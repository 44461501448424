/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../Header/header";
import "./layout.css";
import logo from "../../images/logo.svg";
import BackgroundImage from "gatsby-background-image";

const Layout = ({ localization, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      desktop: file(relativePath: { eq: "abstract_last_final.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        localization={localization}
      />
      <div
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
          backgroundColor: "transparent",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            textAlign: "center",
          }}
        >
        <BackgroundImage
          fluid={data.desktop.childImageSharp.fluid}
          backgroundColor={`#0a0a0d`}
          Tag={"section"}
        >
          <img
            style={{
              margin: "0 auto",
            }}
            src={logo}
            alt="logo"
          />
          </BackgroundImage>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
