import PropTypes from "prop-types";
import React from "react";
import FancyLogo from "./FancyLogo/fancyLogo";

import styles from "./header.module.css";
import HeaderNavigation from "./Navigation/headerNavigation";

const Header = ({ localization, siteTitle }) => (
  <header className={styles.header}>
    <div className={styles.innerContainer}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{localization.headerTitle}</span>
        <br />
        <span className={styles.subTitle}>{localization.headerSubTitle}</span>
      </div>
      <div className={styles.logoWrapper}>
        <FancyLogo />
      </div>
      {/* <div className={styles.navigationWrapper}>
        <HeaderNavigation currentPage={0} localization={localization} />
      </div>
      */}
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
