import PropTypes from "prop-types";
import React from "react";
import styles from "./headerNavigation.module.css";

const HeaderNavigation = ({ localization, size }) => (
  <div className={styles.navWrapper}>
    <a className={styles.links} href={localization.homeHref}>
      {localization.home}
    </a>
    <a className={styles.links} href={localization.contactPageHref}>
      {localization.contact}
    </a>
    <a className={styles.links} href={localization.aboutusPageHref}>
      {localization.aboutus}
    </a>
    <a className={styles.links} href={localization.servicesPageHref}>
      {localization.services}
    </a>
    <a className={styles.links} href={localization.blogPageHref}>
      {localization.blog}
    </a>
  </div>
);

HeaderNavigation.propTypes = {};

HeaderNavigation.defaultProps = {};

export default HeaderNavigation;
